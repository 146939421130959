
  ////// FADE IN TOP //////

  @keyframes fadeInTop {
    0% {
      opacity: 0;
      transform: translateY(100px);
    } 100% {
      opacity: 1;
      transform: translateY(0px);
      
    } 
  }

  @keyframes fadeInLongTop {
    0% {
      opacity: 0;
      transform: translateY(300px);
    } 100% {
      opacity: 1;
      transform: translateY(0px);
      
    } 
  }
  
  .utils__fade-in-fast-top,
  .utils__fade-in-top {
    opacity: 0;
    transition: fadeInTop 1s ease-in both;
  }

  .utils__fade-in-top.appear {
    animation: fadeInTop 1s ease-in-out both 1s; 
  }

  .utils__fade-in-fast-top.appear {
    animation: fadeInTop 1s ease-in-out both; 
  }

  /////////////////////////////////////////////////
  /// 
  /// ////// FADE IN BOTTOM //////

  @keyframes fadeInBottom {
    0% {
      opacity: 0;
      transform: translateY(-100px);
    } 100% {
      opacity: 1;
      transform: translateY(0px);
      
    } 
  }

  @keyframes fadeInLongBottom {
    0% {
      opacity: 0;
      transform: translateY(-300px);
    } 100% {
      opacity: 1;
      transform: translateY(0px);
      
    } 
  }
  
  .utils__fade-in-fast-bottom,
  .utils__fade-in-bottom {
    opacity: 0;
    transition: fadeInBottom 1s ease-in both;
  }

  .utils__fade-in-bottom.appear {
    animation: fadeInBottom 1s ease-in-out both 1s; 
  }

  .utils__fade-in-fast-bottom.appear {
    animation: fadeInBottom 1s ease-in-out both; 
  }

  /////////////////////////////////////////////////

  ////// FADE IN CENTER //////

  @keyframes fadeInCenter {
    0% {
      opacity: 0;
      transform: scale(0.2);
    } 100% {
      opacity: 1;
      transform: scale(1);
      
    } 
  }

  @keyframes fadeInLongCenter {
    0% {
      opacity: 0;
      transform: scale(0.2);
    } 100% {
      opacity: 1;
      transform: scale(1);
      
    } 
  }
  
  .utils__fade-in-fast-center,
  .utils__fade-in-center {
    opacity: 0;
    transition: fadeInCenter 1s ease-in both;
  }

  .utils__fade-in-center.appear {
    animation: fadeInCenter 1s ease-in-out both 1s; 
  }

  .utils__fade-in-fast-center.appear {
    animation: fadeInCenter 1s ease-in-out both; 
  }

  ///////////////////////////////////////////////// 

  ////// FADE IN RIGHT //////

  @keyframes fadeInRight {
    0% {
      opacity: 0;
      transform: translateX(-100px);
    } 100% {
      opacity: 1;
      transform: translateX(0px);
      
    } 
  }

  @keyframes fadeInLongRight {
    0% {
      opacity: 0;
      transform: translateX(-300px);
    } 100% {
      opacity: 1;
      transform: translateX(0px);
      
    } 
  }
  
  .utils__fade-in-fast-right,
  .utils__fade-in-right {
    opacity: 0;
    transition: fadeInRight 1s ease-in both;
  }

  .utils__fade-in-right.appear {
    animation: fadeInRight 1s ease-in-out both 1s; 
  }

  .utils__fade-in-fast-right.appear {
    animation: fadeInRight 1s ease-in-out both; 
  }

  /////////////////////////////////////////////////
    
    ////// FADE IN LEFT //////

    @keyframes fadeInLeft {
      0% {
        opacity: 0;
        transform: translateX(100px);
      } 100% {
        opacity: 1;
        transform: translateX(0px);
        
      } 
    }
  
    @keyframes fadeInLongLeft {
      0% {
        opacity: 0;
        transform: translateX(300px);
      } 100% {
        opacity: 1;
        transform: translateX(0px);
        
      } 
    }
    
    .utils__fade-in-fast-left,
    .utils__fade-in-left {
      opacity: 0;
      transition: fadeInLeft 1s ease-in both;
    }
  
    .utils__fade-in-left.appear {
      animation: fadeInLeft 1s ease-in-out both 1s; 
    }
  
    .utils__fade-in-fast-left.appear {
      animation: fadeInLeft 1s ease-in-out both; 
    }
  
    /////////////////////////////////////////////////
    
  @keyframes fillButton {
    0% {
        width: 0%;
    }
    100% {
        width: 100%
    }
  }
    
    
    /////////////////////////////////////////////////
    
    ////// FADE IN BOUNCE //////

    @keyframes fadeInTry {
      0%{
        opacity: 1;
        transform: scale(1) skewX(0deg);
      }
      39% {
        opacity: 1;
        transform: scale(1) skewX(0deg);
      }

      40%, 44%, 58%, 61%, 65%, 69%, 73%{
        opacity: 1;
        transform: scale(1) skewX(0deg);
      }
    
      41% {
        transform: scale(1.2) skewX(10deg);
      }
    
      42% {
        transform: scale(1) skewX(-10deg);
      }
    
      59% {
        transform: scale(1.3) skewX(40deg) skewY(10deg);
      }
    
      60% {
        transform: scale(1) skewX(-40deg) skewY(-10deg);
      }
    
      63% {
        transform: skewX(10deg) skewY(-5deg);
      }
    
      70% {
        transform: scale(1.5) skewX(-50deg) skewY(-20deg);
      }
    
      71% {
        
        transform:  scale(1) skewX(10deg) skewY(-10deg);
      }

      85% {
        transform: scale(1) skewX(-90deg) skewY(-90deg);
      }
      95% {
        transform: scale(1) skewX(-90deg) skewY(-90deg);
      }
      100% {
        transform: scale(0) skewX(-90deg) skewY(-90deg);
      }
    }
  
    .utils__fade-in-fast-try {
      opacity: 1;
      transition: fadeInTry 3s ease-in-out both 1s;
    }
  
    .utils__fade-in-fast-try.appear {
      animation: fadeInTry 3s both 1s; 
    }
  
    /////////////////////////////////////////////////
    /// 
    

    @keyframes fadeInTry1 {
      0%, 100% {
        opacity: 1;
      }
    
    }
  
    .utils__fade-in-fast-try1 {
      opacity: 1;
      transition: fadeInTry1 3s ease-in-out both 1s;
    }
  
    .utils__fade-in-fast-try1.appear {
      animation: fadeInTry1 3s both 1s; 
    }

    .utils__img {
      position: absolute;
      width: 100%;
      height: auto;
      opacity: 0;
      transition: opacity 1s ease-in-out;
    }
    
    .utils__visible {
      opacity: 1;
    }
    
    .utils__hidden {
      opacity: 0;
    }

    //////////////////////////////////////////////////////////////
    
    @keyframes animateGoLeft {
      0% {
          transform: rotate(-225deg) translate(-5px, -5px);
      }
      50% {
          transform: rotate(-225deg) translate(5px, 5px);
      }
      100% {
          transform: rotate(-225deg) translate(-5px, -5px);
      }
  }
  
  @keyframes animateGoRight {
      0% {
          transform: rotate(-45deg) translate(-5px, -5px);
      }
      50% {
          transform: rotate(-45deg) translate(5px, 5px);
      }
      100% {
          transform: rotate(-45deg) translate(-5px, -5px);
      }
  }

    //////////////////////////////////////////////////////////////