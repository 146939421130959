* {
    font-family: 'Montserrat', 'G20 Classic', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: 0px;
    text-decoration: none;
}

html, body {
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
    overflow-x: hidden;
    user-select: none;
    background-color: #111;
    font-weight: normal;

    &::-webkit-scrollbar {
        width: 0.01px;
        height: 0.01px;
        background-color: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 0.01px;
    }
}

button {
    border: none;
    background-color: transparent;
}

//

@font-face {
    font-family: 'Graffiti regular';
    src: url('../../fonts/SpriteGraffiti-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Graffiti extras';
    src: url('../../fonts/SpriteGraffiti-Extras.ttf') format('truetype');
}

@font-face {
    font-family: 'Graffiti shadow';
    src: url('../../fonts/SpriteGraffiti-Shadow.ttf') format('truetype');
}

@font-face {
    font-family: 'G20 Classic';
    src: url('../../fonts/Generic-G20-FR-Classic-DEMO.ttf') format('truetype');
}

@font-face {
    font-family: 'G20 Typic';
    src: url('../../fonts/Generic-G50-CC-Typic-DEMO_1.ttf') format('truetype');
}
